body {
    font-family: 'Kumbh Sans', sans-serif;
}

.service-container {
    display: flex;
    justify-content: center;
    background-color: #f6f6f6;
    border-radius: 1rem;
    font-family: 'Kumbh Sans', sans-serif;
    margin-top: 70px;
}

.person {
    margin: 10px;
}

.btn {
    display: flex;
    justify-content: space-evenly;
    background: linear-gradient(to bottom,
            #f6f6f6,
            rgba(130, 136, 129, 0.466));
    grid-column-gap: 100px;
    margin-bottom: 15px;
    padding-bottom: 20px;
}

button.service-container {
    padding: 10px 40px;
    border-radius: 10px;
    color: white;
    background-color: #11998e;
    font-size: 0.9vw;
    font-weight: 400;
    text-align: center;
    justify-content: center;
    border: none;
    text-decoration: none;
}

img.services-image {
    height: 80vh;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1rem;
}

.showmore-button {
    all: unset;
    color: #20ad3f;
}

.showmore-button:hover {
    background: none;
}

p.service-text {
    padding: 30px 50px;
}

p.serv-p {
    padding: 20px;
}

h1.service-title,
h2.service-title,
h3.service-title,
p.service-title {
    padding: 20px 20px;
}

.picture-container, .picture-container-img {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: linear-gradient(to bottom, #f6f6f6, rgba(130, 136, 129, 0.466));;
    border-radius: 1rem;
    font-family: 'Kumbh Sans', sans-serif;
}

.picture-container-img:hover { 
    transform: scale(1.1);
}

h4 {
    text-decoration: underline;
    justify-content: center;
    display: flex;
    color: #11998e;
}
h1.service  {
    display: flex;
    justify-content: center;
    background-color: #f6f6f6;
    color: #11998e;
}

@media screen and (max-width: 700px) {
    img.services-image {
        width: 100%;
        height: 80%;
    }

    .btn {
        font-size: 10px;
    }

    a.link {
        justify-content:flex-start;
    }

    h1.service-title,
    h2.service-title,
    h3.service-title,
    p.service-title {
        justify-content: center;
        text-align: center;
    }
}