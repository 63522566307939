.grid-container-2 {
    padding: 10px;
    color: #333461;
    position: left;
}

img.image-3,
h5.c-title,
p.c-text {
    display: block;
    padding: 5px;
}

.contact-container {
    padding: 80px 35px;
}

p.contact-text {
    margin-top: 0;
    margin-bottom: 15px;
    color: #20ad96;
    font-size: 26px;
    line-height: 36px;
    font-weight: 600;
}

p.contact-info {
    margin-top: 0;
    margin-bottom: 5px;
    color: #4a556c;
    font-size: 17px;
    line-height: 28px;
}

h1.title-contact {
    text-decoration: underline;
    text-transform: uppercase;
}

h1.contact-greeting {
    margin: 0 auto;
    margin-top: 20px;
    width: 400px;
    letter-spacing: .2rem;
    white-space: nowrap;
    overflow: hidden;
    border-bottom: 5px solid #20ad96;
    animation: animate 2s linear forwards;
}

@keyframes animate {
    0% { 
      width: 0px;
    }
    100% {
      width: 100%;
    }
  }

.w-layout-grid,
.cta-component,
.contact-us {
    padding-top: 77px;
}

.enquiry-form-wrapper {
    background-color: #d9e3db;
    border: 1px solid #20ad3f;
}

@media screen and (max-width: 700px) {

    .grid-container,
    .enquiry-form-wrapper {
        width: 100%;
    }
    
    button {
        font-size: 3vw;
    }
    .w-layout-grid,
    .cta-component,
    .contact-us {
        padding-top: 30px;
    }
}